import { Button } from '@teamfoster/sdk/button';
import { videoUrl } from './example-media';
import { addDays, addYears } from 'date-fns';

export const title = [
  'The quick brown fox jumps over the lazy dog',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit',
  'Lipsum asmet',
  'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quasi omnis autem enim ratione? ',
];

export const names = [
  'Gerrie van der Sop',
  'Jan de Vries',
  'Anna van den Berg',
  'Liesbeth ten Bosch',
  "Bram van't Klooster",
  'Sanne op den Dam',
  'Eva van het Hof',
  'gerrie van der sop',
  'JAN DE VRIES',
  'Gerrit Stop',
  'GERRIT stop',
  'gerrit Stop',
  'Anne-Marie Jansen',
  'Sara De-Leeuw',
  'Anna-Sophie van Dijk',
  'Elise Peters-Bakker',
  'Lotte-Jean van der Meer',
  'Johannes Christiaan de Boer',
  'Anne Marie de Vries',
  'Jeroen-Lukas van Kampen',
  'Maaike van den Berg',
  'Rob ter Haar',
  'Marieke de Groot',
  'Sander van ‘t Hoen',
  'Pieter op de Kamp',
  'Juan Carlos Garcia Perez',
  'Maria-Sofia Sanchez-Garcia',
  'Ali ibn Ahmed',
  'Ming-Wei Chen',
  'Kim Soo-Hyun',
  'O’Leary',
  'D’Arcy',
  'M’Baku',
  'Martijn de Wit Bakker',
  'Anna Jansen Bos',
  'J.P. Janssen',
  'T. van de Kamp',
  'G. van’t Zand',
];

export const subtitle = ['The quick brown fox jumps', 'Lipsum asmet', 'Over the lazy dog'];

export const paragraph = [
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla nec odio nec nunc',
  'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Mauris interdum pulvinar tellus ac commodo. Maecenas convallis sem metus, in volutpat arcu venenatis quis. Fusce consectetur tincidunt tincidunt. Nam dignissim ante ac justo dictum, at sagittis augue semper. Ut pellentesque facilisis mattis. Cras consequat, mauris maximus sagittis blandit, felis felis viverra magna, non convallis dui orci vel mi.',
  `Sed quis magna id ex sollicitudin euismod quis ut nunc. Nulla non finibus mauris. Aliquam pretium arcu non consectetur ultrices. In hac habitasse platea dictumst. Aliquam rhoncus velit et ipsum dapibus, non sollicitudin lorem aliquet. Etiam quis nunc congue, ornare orci a, mollis est. Morbi orci leo, cursus non ipsum sodales, pellentesque suscipit diam.

  Duis non ipsum at ante maximus volutpat. Phasellus egestas ipsum sit amet ante fermentum facilisis. Mauris at lobortis enim, a sodales dui. Nullam consequat, erat sed finibus feugiat, tortor eros semper tellus, vitae accumsan mi nulla vel est. Sed varius orci id tincidunt commodo. Praesent porttitor, ipsum eget luctus laoreet, nisl mi viverra orci, id dictum purus orci vel enim. Vivamus tincidunt, lectus in accumsan tincidunt, lacus quam euismod risus, sagittis ultrices orci dui ac ligula. Praesent hendrerit eu nunc id mollis.
  `,
  'Sed quis magna id ex sollicitudin euismod quis ut nunc. Nulla non finibus mauris. Aliquam pretium arcu non consectetur ultrices. In hac habitasse platea dictumst. Aliquam rhoncus velit et ipsum dapibus, non sollicitudin lorem aliquet. ',
  'Lorem ipsum dolor smit',
];

export const button: Button[] = [
  {
    url: '/contentblokken',
    routerLink: ['/', 'contentblokken'],
    text: 'Button routerlink',
  },
  {
    url: 'https://www.teamfoster.nl/',
    text: 'Button url',
  },
];
// Example events with all possible date combinations
export const dateRange = [
  {
    startDate: addDays(new Date(), -1),
    endDate: addDays(new Date(), 1),
  },
  {
    startDate: new Date(),
    endDate: null,
  },
  {
    startDate: addDays(new Date(), 2),
    endDate: addDays(new Date(), 10),
  },
  {
    startDate: addDays(new Date(), 34),
    endDate: null,
  },
  {
    startDate: addYears(new Date(), 1),
    endDate: null,
  },
  {
    startDate: addYears(addDays(new Date(), 2), 1),
    endDate: addYears(addDays(new Date(), 3), 1),
  },
  {
    startDate: addYears(addDays(new Date(), 2), 1),
    endDate: addYears(addDays(new Date(), 20), 1),
  },
];

export const wysiwyg = `
<p>
      Lorem ipsum dolor sit amet,
      <strong>consectetur</strong>
      adipiscing elit. Quisque eu ligula vitae augue ultrices sollicitudin. Cras dignissim ipsum turpis, quis posuere metus pulvinar sit
      amet. Morbi dictum mi in ultrices dignissim.
      <em>Etiam a tellus in orci luctus laoreet eu quis enim</em>
      . Curabitur ornare augue arcu. Vivamus ut orci dictum,
      <u>pellentesque</u>
      arcu eu, posuere velit. Curabitur gravida purus elit, non feugiat tortor iaculis et. Vestibulum facilisis massa a bibendum semper. Nam
      non imperdiet est. Nam id tincidunt orci. Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit
      amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis,
      ut accumsan turpis tortor nec magna.
    </p>

    <ol>
      <li>lakdsf</li>
      <li>asdf</li>
      <li>asdfklajsdfklj</li>
      <li>adsfkljalskdjf sdklafj lkasdj flka sjdflkaj sdflkajsdf lkasjdf</li>
    </ol>

    <p>
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>

    <ul>
      <li>asdjklf</li>
      <li>asdfalksjdf</li>
      <li>asdf</li>
      <li>sdafkljasd;f</li>
    </ul>

    <p>
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>

    <blockquote>
      <p>dit is een citaat in de lopende content</p>
    </blockquote>

    <p>
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>

    <p>
      <br />
    </p>

    <p>
      <span class="fr-video fr-deletable fr-fvc fr-dvb">
        <iframe
          width="1280"
          height="720"
          src="${videoUrl}"
        ></iframe>
      </span>
    </p>

    <table>
      <tbody>
        <tr>
          <td>asdf</td>
          <td>lkjdsfj</td>
          <td>lkjdslkj</td>
        </tr>
        <tr>
          <td>lksdjf</td>
          <td>lkjlkjdsflksdlkfj</td>
          <td>ldslfkj</td>
        </tr>
      </tbody>
    </table>

    <p>
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>

    <table>
      <tbody>
        <tr>
          <td>fsa</td>
          <td>lkfdjs lkj</td>
          <td>lkj fdlksj&nbsp;</td>
          <td>lkj flkj</td>
          <td>&nbsp;lkjflkj</td>
          <td>lk jflk</td>
          <td>j flkj</td>
          <td>&nbsp;lkjf</td>
        </tr>
        <tr>
          <td>jfklsd jl</td>
          <td>kjf lk</td>
          <td>jwlkd jlkjf lkj</td>
          <td>lkjflk jsdlfkj</td>
          <td>
            Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet
            bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
            <br />
          </td>
          <td>jflkasj fk</td>
          <td>jlkfj slkdjf&nbsp;</td>
          <td>lkjd&nbsp;</td>
        </tr>
      </tbody>
    </table>

    <p>
      Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet
      bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>


    <div class="fr-img-space-wrap">
      <span class="fr-img-caption fr-fic fr-dib" style="width: 998px">
        <span class="fr-img-wrap">
          <img
            src="//spoorzone.fstr.io/media/uploads/Rectangle 142.png"
            data-url="//spoorzone.fstr.io/media/uploads/Rectangle 142.png"
            class="fr-fic fr-dib"
          />
          <span class="fr-inner">Afbeelding caption</span>
        </span>
      </span>

      <p class="fr-img-space-wrap2">&nbsp;</p>
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </div>




		<p>
      Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet
      bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>

`;

export const wysiwygVideo = `
<p>
      Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet
      bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>
 <p>
      <span class="fr-video">
        <iframe
          width="1280"
          height="720"
          src="${videoUrl}"
        ></iframe>
      </span>
    </p>
				<p>
      Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh vitae posuere. Morbi sollicitudin, odio sit amet
      bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>
`;

export const wysiwygSimple = `
<p>
      Lorem ipsum dolor sit amet,
      <strong>consectetur</strong>
      adipiscing elit. Quisque eu ligula vitae augue ultrices sollicitudin. Cras dignissim ipsum turpis, quis posuere metus pulvinar sit
      amet. Morbi dictum mi in ultrices dignissim.
      <em>Etiam a tellus in orci luctus laoreet eu quis enim</em>
      . Curabitur ornare augue arcu. Vivamus ut orci dictum,
      <u>pellentesque</u>
      arcu eu, posuere velit.
      <a href="#">Curabitur gravida</a>
      purus elit, non feugiat tortor iaculis et. Vestibulum facilisis massa a bibendum semper. Nam non imperdiet est. Nam id tincidunt orci.
      Cras auctor aliquet diam, ac dictum nisi venenatis nec. Praesent efficitur porta tellus sit amet accumsan. Etiam tempus interdum nibh
      vitae posuere. Morbi sollicitudin, odio sit amet bibendum ultricies, erat enim cursus felis, ut accumsan turpis tortor nec magna.
    </p>
`;

export const wysiwygSimpleShort = `
<p>
      Lorem ipsum dolor sit amet,
      <strong>consectetur</strong>
      adipiscing elit. Quisque eu ligula vitae augue ultrices sollicitudin.
      <em>Etiam a tellus in orci luctus laoreet eu quis enim</em>.
      <u>pellentesque</u>
      arcu eu, posuere velit.
      <a href="#">Curabitur gravida</a>
      purus elit, non feugiat tortor iaculis et. Vestibulum facilisis massa a bibendum semper. Nam non imperdiet est. Nam id tincidunt orci.
      Cras auctor aliquet diam, ac dictum nisi venenatis nec.
    </p>
`;

const backgroundTheme = [
  {
    name: 'Donkerblauw',
    value: 'primary',
  },
  {
    name: 'Lichtgrijs',
    value: 'gray-100',
  },
];
