import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromDetail from '../reducers/themes-detail.reducer';
import * as fromRoot from 'src/app/store';
import { getRouterState } from 'src/app/store';
import { Theme } from '../../models';

export const getThemesDetailState = createSelector(fromFeature.getThemeState, (state: fromFeature.ThemesState) => state.detail);

export const getThemesDetailEntities = createSelector(getThemesDetailState, fromDetail.getThemesDetailEntities);
export const getThemesDetailLoaded = createSelector(getThemesDetailState, fromDetail.getThemesDetailLoaded);
export const getThemesDetailLoading = createSelector(getThemesDetailState, fromDetail.getThemesDetailLoading);

export const getSelectedTheme = createSelector(getThemesDetailEntities, fromRoot.getRouterState, (entities, router): Theme => {
  return router.state && entities[router.state.params?.['slug'] || router.state.data?.['slug']];
});

export const getSelectedThemeLoading = createSelector(getThemesDetailLoading, fromRoot.getRouterState, (slugs, router): boolean => {
  return router.state && slugs.includes(router.state.params?.['slug'] || router.state.data?.['slug']);
});

export const getSelectedThemeLoaded = createSelector(getThemesDetailLoaded, fromRoot.getRouterState, (slugs, router): boolean => {
  return router.state && slugs.includes(router.state.params?.['slug'] || router.state.data?.['slug']);
});
