<section>
  <div class="container section-inset-y">
    <h1>Home</h1>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. In erat est, facilisis sed mattis a, volutpat sit amet leo. Mauris volutpat
      consequat nisl, vitae imperdiet magna tristique in. Vestibulum convallis viverra placerat. Morbi et ipsum eu est vestibulum dictum.
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nulla eu dolor in nisl dignissim volutpat. Sed fermentum in dolor vel
      sagittis. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos himenaeos. Integer laoreet lectus vitae
      placerat scelerisque. Aliquam vulputate libero vel aliquet egestas. Ut a libero eu metus varius rutrum sit amet in sapien. Vestibulum
      condimentum dolor in urna pellentesque, sit amet lobortis nibh volutpat. Maecenas eu consequat ante. Sed eu cursus leo. Ut risus
      ipsum, placerat scelerisque posuere ut, feugiat non mauris.
    </p>
    <a class="button button--primary mb-4" [routerLink]="['/', 'styleguide']">styleguide</a>
  </div>
</section>
