<app-bg-wave
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-secondary)'"
  [strokeColor]="'transparent'"
  class="d-none d-sm-block cta-bgwave"
></app-bg-wave>
<section class="content-block-cta">
  <main class="container container-size-xs">
    <span class="fw-bold text-primary" *ngIf="block.subtitle">{{ block.subtitle }}</span>
    <h1 class="h2 text-light">{{ block.title }}</h1>
    <div class="py-3 mb-0" [innerHTML]="block.text" *ngIf="block.text"></div>
    <a *ngIf="block.button?.url" class="button button--primary button--md" [href]="block.button?.url">
      {{ block.button?.text || 'Lees meer' }}
    </a>
  </main>
</section>
<app-bg-wave
  class="cta-bgwave bg-wave--flipped d-none d-sm-block"
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-secondary)'"
  [strokeColor]="'transparent'"
></app-bg-wave>
