import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { CookieSettingsGuard } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryGuard } from '@teamfoster/sdk/dictionary-ngrx';
import { MenuGuard } from '@teamfoster/sdk/menu-ngrx';
import { NotFoundComponent } from './404/404.component';
import { AuthGuard } from './auth/guards/auth.guard';
import { CheckAuthGuard } from './auth/guards/checkAuth.guard';
import { LikesGuard } from './likes/guards';
import { SocialChannelsGuard } from './social-media/guards';
import { ThemesGuard } from './themes/guards/themes.guard';

const appGuards = [CookieSettingsGuard, DictionaryGuard, MenuGuard, CheckAuthGuard, SocialChannelsGuard, LikesGuard, ThemesGuard];
const signedInGuards = [AuthGuard];

const routes: Routes = [
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/style-guide.module').then(m => m.StyleGuideModule),
    // canActivate: [...appGuards],
  },
  {
    path: 'account',
    loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
    canActivate: [...appGuards],
  },
  {
    path: 'profiel',
    loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
    canActivate: [...appGuards, ...signedInGuards],
  },
  {
    path: 'initiatieven',
    loadChildren: () => import('./initiatives/initiatives.module').then(m => m.InitiativesModule),
    canActivate: [...appGuards],
  },
  {
    path: 'evenementen',
    redirectTo: '/activiteiten',
    pathMatch: 'full',
  },
  {
    path: 'activiteiten',
    loadChildren: () => import('./events/events.module').then(m => m.EventsModule),
    canActivate: [...appGuards],
  },
  {
    path: 'contentblokken',
    loadChildren: () => import('./content-blocks/content-blocks.module').then(m => m.ContentBlocksModule),
    canActivate: [...appGuards],
  },
  {
    path: 'verhalen',
    loadChildren: () => import('./stories/stories.module').then(m => m.StoriesModule),
    canActivate: [...appGuards],
  },
  {
    path: 'thema',
    loadChildren: () => import('./themes/themes.module').then(m => m.ThemesModule),
    canActivate: [...appGuards],
  },
  //{
  //  path: 'pagina',
  //  loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
  //  canActivate: [...appGuards],
  //},
  {
    path: '404',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '',
    pathMatch: 'prefix',
    loadChildren: () => import('./landing-pages/landing-pages.module').then(m => m.LandingPagesModule),
    canActivate: [...appGuards],
  },

  {
    path: '**',
    redirectTo: '/404',
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { scrollPositionRestoration: 'disabled', anchorScrolling: 'disabled', bindToComponentInputs: true }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
