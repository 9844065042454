import { Injectable } from '@angular/core';


import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { SetTitle, SetTags } from '../store';
import { StringUtility } from '@teamfoster/sdk/text-utility';
import { FromDictionaryPipe, getDictionaryLoaded, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Injectable({ providedIn: 'root' })
export class MetaGuard  {
  private dict = new FromDictionaryPipe(this.lang);

  constructor(private store: Store, private lang: LanguageService) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(getDictionaryLoaded).pipe(
      filter(loaded => loaded),
      tap(loaded => {
        setTimeout(() => {
          this.store.dispatch(SetTitle({ title: this.dict.transform('meta-titel') }));
          this.store.dispatch(
            SetTags({
              properties: {
                'description': StringUtility.Truncate(this.dict.transform('meta-omschrijving'), 157, '...'),
                'og:image': this.dict.transform('meta-afbeelding'),
                'og:title': this.dict.transform('meta-titel'),
                'og:description': StringUtility.Truncate(this.dict.transform('meta-omschrijving'), 157, '...'),
                'twitter:card': 'summary_large_image',
                'twitter:image': this.dict.transform('meta-afbeelding'),
                'twitter:title': this.dict.transform('meta-titel'),
                'twitter:description': StringUtility.Truncate(this.dict.transform('meta-omschrijving'), 157, '...'),
              },
            })
          );
        });
      }),
      take(1)
    );
  }
}
