import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, ChangeDetectorRef, Output, EventEmitter, Inject, PLATFORM_ID, InjectionToken } from '@angular/core';
import { NavigationStart, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import * as fromMenu from '@teamfoster/sdk/menu-ngrx';
import { Store } from '@ngrx/store';
import { SignOut } from 'src/app/auth/store';
import { Profile } from 'src/app/auth/models';
import { MatDialog } from '@angular/material/dialog';
import { ParticipateDialogComponent } from 'src/app/standalone/components/participate-dialog/participate-dialog.component';
import { Theme } from 'src/app/themes/models';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
})
export class TopbarComponent implements OnInit {
  @Input() loading: boolean = false;
  @Input() menuActive: boolean = false;
  @Input() searchActive: boolean = false;
  @Input() logoClass: string = '';
  @Input() menu: fromMenu.MenuItem[] = [];
  @Input() themes: Theme[] = [];
  @Input() theme: string = '';
  @Input() isSignedIn?: boolean;
  @Input() profile!: Profile;

  @Output() toggleOverlay = new EventEmitter<void>();
  @Output() closeOverlay = new EventEmitter<void>();
  @Output() openOverlay = new EventEmitter<void>();

  @Output() searchOpen = new EventEmitter<boolean>();

  showActionsSubnav = false;

  fixed = false;
  //searchActive = false;

  get logoUrl() {
    if (this.menuActive) {
      return '/assets/gfx/onze-ijssel-logo.svg';
    }
    switch (this.theme) {
      case 'palette-dark':
        return '/assets/gfx/onze-ijssel-logo-inverted.svg';
      default:
        return '/assets/gfx/onze-ijssel-logo.svg';
    }
  }

  constructor(
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router,
    private store: Store,
    private dlg: MatDialog
  ) {
    router.events.subscribe(s => {
      if (s instanceof NavigationStart && this.menuActive) {
        this.closeOverlay.emit();
        this.searchOpen.emit(false);
        this.cd.detectChanges();
        //this.searchActive = false;
      }
    });
  }

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      fromEvent(window, 'scroll')
        .pipe(debounceTime(20))
        .subscribe(a => {
          // show scrollbar after windowinnerheight (window.innerHeight)
          if (window.scrollY > 104) {
            this.fixed = true;
          } else {
            this.fixed = false;
          }
          this.cd.detectChanges();
        });
    }
  }

  isHomeUrl(url: string | undefined) {
    const homeMatches = ['#', '/', ''];
    return homeMatches.includes(url || '');
  }

  toggleSearch() {
    this.searchOpen.emit(!this.searchActive);
    //this.searchActive = !this.searchActive;

    //if (this.searchActive && !this.menuActive) {
    //  this.openOverlay.emit();
    //} else if (!this.searchActive && this.menuActive) {
    //  this.closeOverlay.emit();
    //}
  }

  signOut() {
    this.store.dispatch(SignOut());
  }

  participate() {
    this.dlg.open(ParticipateDialogComponent);
  }
}
