import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Initiative } from '../../../initiatives/models';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-related-initiatives',
  templateUrl: './related-initiatives.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedInitiativesComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() initiatives: Initiative[] | undefined;

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private lang: LanguageService) {}
}
