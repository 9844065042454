<div
  class="custom-field form-location"
  *ngIf="group.get(config.name) as field"
  [class.has--label]="!!config.label"
  [class.has--sub-label]="!!config.subLabel"
  [ngClass]="{
    'custom-field--invalid': field.invalid && (field.touched || field.dirty)
  }"
>
  <ng-container [formGroup]="group">
    <mat-form-field class="form-location__hidden-formfield">
      <input
        matInput
        [ngClass]="{
          'custom-field--invalid': field.invalid && (field.touched || field.dirty)
        }"
        [formControlName]="config.name"
        [hidden]="true"
      />
    </mat-form-field>
  </ng-container>

  <span class="label">
    {{ config.label }}
    <span class="mat-form-field-required-marker" *ngIf="isRequired(group.get(config.name))">*</span>
  </span>

  <div class="custom-field__field">
    <ng-container [formGroup]="group">
      <mat-checkbox class="mb-3" formControlName="entireArea" (change)="entireAreaChange($event)">
        <span>Dit initiatief vindt plaats rondom de gehele IJssel</span>
      </mat-checkbox>
    </ng-container>
    <form [formGroup]="localForm">
      <mat-form-field class="form-location__field" appearance="fill">
        <input type="text" matInput formControlName="query" [matAutocomplete]="auto" [placeholder]="config.placeholder || ''" />

        <app-icon anchor="icon-search" matSuffix></app-icon>

        <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn">
          <mat-option *ngFor="let option of locationResults" [value]="option" (click)="addLocationFromResults(option)">
            {{ option.formatted_address }}
          </mat-option>
        </mat-autocomplete>
      </mat-form-field>
    </form>

    <div *ngIf="apiLoaded | async">
      <google-map
        #maps
        width="100%"
        [options]="options"
        (mapClick)="setMarker($event)"
        (mapDrag)="mapMoved = true"
        [class.is--disabled]="useDefaultLocationControl?.value"
      >
        <ng-container *ngIf="useDefaultLocationControl?.value !== true">
          <map-marker
            *ngFor="let m of group.get(config.name)?.value; let i = index"
            [position]="markerLocation(m)"
            [options]="markerOptions"
            [label]="getMarkerLabel(i + 1)"
            [icon]="markerIcon || ''"
            (mapDragend)="updateMarker($event, i)"
            (mapClick)="activeMarker = m"
          ></map-marker>
        </ng-container>
        <!--[icon]="markerIcon || ''"-->
      </google-map>
    </div>

    <div *ngIf="group.get(config.name)?.value?.length && useDefaultLocationControl?.value !== true">
      <div class="d-flex gap-2 flex-wrap my-2">
        <strong class="text-muted-light fs-body-sm fw-normal">{{ 'formulier-locatie-geselecteerd-titel' | fromDictionary }}</strong>

        <button class="button button-link button--xs button--danger ms-auto h-auto" type="button" (click)="removeAll()">
          <span>{{ 'formulier-locatie-alle-verwijderen' | fromDictionary }}</span>
          <app-icon class="icon-xxs" anchor="icon-cross"></app-icon>
        </button>
      </div>

      <ul class="d-flex flex-column gap-1">
        <li class="text-center" *ngIf="mapMoved">
          <button class="button button--sm button--muted" type="button" (click)="centerMapToFitAllMarkers()">Centreer kaart</button>
        </li>
        <li class="marker-list-item" *ngFor="let m of group.get(config.name)?.value; let i = index">
          <span>{{ i + 1 }}. {{ m.label }}</span>
          <button class="button button-icon button--xs button--light" (click)="removeLocation(m, i)">
            <app-icon class="icon-xxs" anchor="icon-cross"></app-icon>
          </button>
        </li>
      </ul>
    </div>
    <!--<strong *ngIf="group.get(config.name)?.value as location">{{ location | json }}</strong>-->
  </div>

  <div class="custom-field__error-wrapper">
    <mat-error *ngIf="field.invalid && field.dirty">
      {{ field | formError : config.customValidationMessages }}
    </mat-error>
  </div>
</div>
