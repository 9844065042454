import { Image } from '@teamfoster/sdk/image';

const imageUrl = [
  'https://www.hedon-zwolle.nl/zoo-animals-7.webp',
  'https://www.hedon-zwolle.nl/media/TGTE-banner.png',
  'https://www.hedon-zwolle.nl/media/Kinderdisco_0Z8_1146_©RobertWestera_LR.jpg',
  'https://www.hedon-zwolle.nl/media/BenjaminHermansBughousedoorJonathanHerman.jpg',
  'https://www.hedon-zwolle.nl/media/Rick de Leeuw en band 2025 zw w.jpg',
  'https://www.hedon-zwolle.nl/media/Schilder de nacht.png',
  'https://www.hedon-zwolle.nl/media/ODEON JAN MARCEL.png',
  'https://www.hedon-zwolle.nl/media/jan marcel tijdens direct.png',
  'https://www.hedon-zwolle.nl/media/HEADER(5).jpg',
  'https://www.hedon-zwolle.nl/media/20241206_LDaL_MM_03.png',
  'https://www.hedon-zwolle.nl/media/Club Meta credits Stefan Lucassen.jpg',
  'https://www.hedon-zwolle.nl/media/homemade.jpg',
  "https://www.hedon-zwolle.nl/media/hr_4am mc's poppodium hedon zwolle 20241109 © leroy beesemer-53.JPG",
  'https://www.hedon-zwolle.nl/media/Najaar_Posterpicture_Empty_2.png',
  'https://www.hedon-zwolle.nl/media/youtube.png',
  'https://www.hedon-zwolle.nl/media/150219_Kinderdisco_024 (2).jpg',
  'https://www.hedon-zwolle.nl/media/schema.png',
  'https://www.hedon-zwolle.nl/media/kom st paul LIGGEND.png',
  'https://www.hedon-zwolle.nl/media/kom st paul staand.png',
  'https://www.hedon-zwolle.nl/media/Everybody happy header high res.png',
  'https://www.hedon-zwolle.nl/media/Everybody happy FB header.png',
  'https://www.hedon-zwolle.nl/media/raw-definition-2024 kopie.jpg',
  'https://www.hedon-zwolle.nl/media/strobe verlichting liggend.png',
  'https://www.hedon-zwolle.nl/media/STROBE VERLICHTING STAAND.png',
  'https://www.hedon-zwolle.nl/media/kelderkabaal.png',
  'https://www.hedon-zwolle.nl/media/deathcafe 1920x1080.png',
  'https://www.hedon-zwolle.nl/media/Jan van Duikeren & Marcel Veenendaal - Press Pic - Tim van der Zalm.jpg',
  'https://www.hedon-zwolle.nl/media/10cc - Iain-Graham-Paul-Keith-Rick 19 ( drum kit) CR Reinout Bos.jpg',
  'https://www.hedon-zwolle.nl/media/James Walsh 2.jpg',
  'https://www.hedon-zwolle.nl/media/20240309-VDZWOLLE-DELANOGOPI_NOLOGO-68JPG.webp',
  'https://www.hedon-zwolle.nl/media/DEZE FOTO GEBRUIKEN(1).jpeg',
  'https://www.hedon-zwolle.nl/media/DEZE FOTO GEBRUIKEN.jpeg',
  'https://www.hedon-zwolle.nl/media/logo_red1.jpg',
  'https://www.hedon-zwolle.nl/media/c7ab9e55_TBR.jpg',
  'https://www.hedon-zwolle.nl/media/HedocnCrew-041124-lageresolutie-2.jpg',
  'https://www.hedon-zwolle.nl/media/Geen hogere BTW (YouTube Thumbnail).png',
  'https://www.hedon-zwolle.nl/media/0036878405_10.jpg',
  'https://www.hedon-zwolle.nl/media/BANNER whatsaapp(1).png',
  'https://www.hedon-zwolle.nl/media/BANNER whatsaapp.png',
  'https://www.hedon-zwolle.nl/media/BANNER POP RONDE.png',
  'https://www.hedon-zwolle.nl/media/Banner - Groen(1).png',
  'https://www.hedon-zwolle.nl/media/BANNER LENNY MONSOU.png',
  'https://www.hedon-zwolle.nl/media/Design_GU_Hedon_banner(1).jpg',
  'https://www.hedon-zwolle.nl/media/BANNER(1).png',
  'https://www.hedon-zwolle.nl/media/PULSE Hedon cover(2).png',
  'https://www.hedon-zwolle.nl/media/BANNER BROEDERLIEFDE(1).png',
  'https://www.hedon-zwolle.nl/media/Latin Night 1.jpg',
  'https://www.hedon-zwolle.nl/media/Website Banner.jpg',
  'https://www.hedon-zwolle.nl/media/2342342.png',
  'https://www.hedon-zwolle.nl/media/TPB-BlackBar-LA-2.png',
];

export const logo: string[] = [
  '/assets/example-data/batman-5.svg',
  '/assets/example-data/lorem-lorem-1.svg',
  '/assets/example-data/marvel-logo.svg',
  '/assets/example-data/green-lantern-1.svg',
  '/assets/example-data/lorem-lorem.svg',
  '/assets/example-data/superman.svg',
];

export const image: Image[] = imageUrl.map(a => ({ url: a, alt: 'Voorbeeld afbeelding' }));

// Needs to be an embed url, so only replace id
export const videoUrl: string = 'https://www.youtube.com/embed/C0DPdy98e4c';
