import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input, OnInit } from '@angular/core';
import { BaseContent } from '../../../content/models';
import { Initiative } from 'src/app/initiatives/models';
import { Story } from 'src/app/stories/models';
import { Event } from 'src/app/events/models';

@Component({
  selector: 'app-related-content',
  templateUrl: './related-content.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class RelatedContentComponent {
  @Input() title!: string;
  @Input() subtitle!: string;
  @Input() content: BaseContent[] | undefined;

  asInitiative(content: BaseContent): Initiative {
    return content as Initiative;
  }

  asStory(content: BaseContent): Story {
    return content as Story;
  }

  asEvent(content: BaseContent): Event {
    return content as Event;
  }

  trackByFn(index: number, block: any) {
    return block.id;
  }
}
