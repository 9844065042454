<a class="skiplink" id="start-slider" [routerLink]="[]" (click)="focusTo('#eind-slider')">Sla Slider over</a>

<main class="event-card-slider-container">
  <!--<button type="button" class="swiper-button-prev">
    <fstr-icon anchor="line-icon-chevron-left"></fstr-icon>
    <span class="sr-only">Vorige</span>
  </button>-->
  <div class="container-fluid">
    <swiper-container class="swiper" init="false" #swipercontainer>
      <!--@for (event of events(); track event.id) {
        <swiper-slide class="swiper-slide">
          <app-event-card [event]="event"></app-event-card>
        </swiper-slide>
      }-->

      <ng-content></ng-content>
    </swiper-container>
  </div>
  <!--<button type="button" class="swiper-button-next">
    <fstr-icon anchor="line-icon-chevron-right"></fstr-icon>
    <span class="sr-only">Volgende</span>
  </button>-->
</main>

<a class="skiplink" id="eind-slider" [routerLink]="[]" (click)="focusTo('#start-slider')">Terug naar begin slider</a>
