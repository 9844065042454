import { Component, Inject, InjectionToken, Input, OnInit, PLATFORM_ID, ViewChild } from '@angular/core';
import { AbstractControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ContentChange, QuillEditorComponent } from 'ngx-quill';
// import { formField } from '../../models';

import { isPlatformBrowser } from '@angular/common';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';

@Component({
  selector: 'app-wysiwyg-editor',
  templateUrl: './wysiwyg-editor.component.html',
})
export class WysiwygEditorComponent implements OnInit {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  @ViewChild('editor', { static: true }) editor?: QuillEditorComponent;

  modules = {
    toolbar: [['bold', 'italic', 'underline', 'strike']],
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => {
            this.onTab();
            return false;
          },
        },
      },
    },
    clipboard: {
      matchVisual: false,
    },
  };

  fieldFocused = false;

  constructor(private fb: UntypedFormBuilder, @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>) {}

  async ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      await this.loadClipboard();
    }
    // set toolbar options
    if (this.config['toolbar']) {
      this.modules.toolbar = this.config['toolbar'];
    }
  }

  isRequired(ctrl: any) {
    if (!ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator.required;
  }

  showError(field: AbstractControl) {
    return field.invalid && (field.touched || field.dirty);
  }

  async loadClipboard() {
    const Quill = await require('quill');
    const Clipboard = Quill.import('modules/clipboard');
    const Delta = Quill.import('delta');

    class PlainClipboard extends Clipboard {
      onPaste(e: any) {
        e.preventDefault();
        const range = this['quill'].getSelection();
        const text = e.clipboardData.getData('text/plain');
        const delta = new Delta().retain(range.index).delete(range.length).insert(text);
        const index = text.length + range.index;
        const length = 0;
        this['quill'].updateContents(delta, 'silent');
        this['quill'].setSelection(index, length, 'silent');
        this['quill'].scrollIntoView();
      }
    }

    Quill.register('modules/clipboard', PlainClipboard, true);
  }

  onTab() {
    //https://quilljs.com/docs/modules/keyboard
    console.log('tab');
  }
}
