<h2 mat-dialog-title class="d-flex justify-content-between align-items-end mb-4">
  <span class="h5 text-primary mt-3 mb-0">{{ prefix + '-datum-nieuw' | fromDictionary }}</span>

  <button class="ms-auto button button-icon button--muted" type="button" mat-button mat-dialog-close><mat-icon>close</mat-icon></button>
</h2>

<mat-dialog-content>
  <div class="content-form content-form-dialog">
    <div class="form">
      <fstr-dynamic-form [appearance]="'fill'" [config]="formFields!" (formSubmit)="save($event)" [showSubmit]="false">
        <div slot="form-actions" class="d-flex gap-2">
          <button type="button" class="button button--danger" (click)="deleteItem()">
            <mat-icon>delete_forever</mat-icon>
            <span>{{ prefix + '-datum-verwijder' | fromDictionary }}</span>
          </button>

          <button type="submit" class="button button--primary ms-auto">
            {{ prefix + '-verzenden-knoptekst' | fromDictionary }}
          </button>
        </div>
      </fstr-dynamic-form>
    </div>
  </div>
</mat-dialog-content>
