<!-- Laetitia heeft input styling in haar 404 branche! -->
<!--<form class="search-form" [formGroup]="signInForm">
  <mat-form-field class="search-field" appearance="fill" class="search-field" [floatLabel]="'always'">
    <mat-label></mat-label>
    <input type="search" matInput formControlName="searchControl" placeholder="Zoeken op..." />
    <button type="submit" matSuffix>
      <app-icon anchor="icon-search" class="icon-primary"></app-icon>
    </button>
  </mat-form-field>
</form>-->

<form class="text-max-width" [formGroup]="seachForm">
  <mat-form-field class="mat-form-field-search" appearance="fill" [floatLabel]="'auto'">
    <mat-label class="mat-label-zoeken-op">{{ 'zoeken-overlay-zoekveld-label' | fromDictionary }}</mat-label>
    <input class="input-search-text" type="search" matInput formControlName="searchControl" cdkFocusInitial />
    <app-icon matSuffix anchor="icon-search" class="icon-primary p-auto"></app-icon>
  </mat-form-field>
</form>
