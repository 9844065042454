<section class="block-default" [ngClass]="'bg-' + block.backgroundTheme">
  <main class="container container-size-xs">
    <div class="text-center" *ngIf="block.subtitle || block.title">
      <span *ngIf="block.subtitle" class="fw-bold text-secondary">{{ block.subtitle }}</span>
      <h1 class="h2 block-default__title" *ngIf="block.title">
        {{ block.title }}
        <app-icon anchor="icon-squiggle" class="heading-squiggle" *ngIf="block.title"></app-icon>
      </h1>
    </div>

    <div
      *ngIf="block.text"
      class="markdown-content mt-4"
      fstrDynamicContent
      [innerHTML]="block.text || '' | resizeHtmlImages | stripEmbed : cookiePreferences | safe"
    ></div>
    <div *ngIf="block.button?.url" class="d-flex justify-content-center">
      <a class="button block-default__button mt-4" [routerLink]="block.button?.url">
        {{ block.button?.text }}
      </a>
    </div>
  </main>
</section>

<app-bg-wave
  *ngIf="block.backgroundTheme"
  class="bg-wave--flipped"
  [autoPlay]="true"
  [startPos]="'12s'"
  [bgColor]="'var(--bs-' + block.backgroundTheme + ')'"
  [strokeColor]="'transparent'"
></app-bg-wave>
