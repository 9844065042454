import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ThemeLandingPageComponent } from './containers/theme-landing-page/theme-landing-page.component';
import { ThemeDetailGuard } from './guards/theme.guard';
import { ThemesGuard } from './guards/themes.guard';
import { ThemeMetaGuard } from './guards/theme-meta.guard';

const routes: Routes = [
  {
    path: ':slug',
    component: ThemeLandingPageComponent,
    canActivate: [ThemeDetailGuard, ThemeMetaGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule {}
