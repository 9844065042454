import { Component, Input } from '@angular/core';
import { ContentBlock } from '../../models';
import { Image } from 'src/app/core/models';

@Component({
  selector: 'app-content-block-maps',
  templateUrl: './content-block-maps.component.html',
})
export class ContentBlockMapsComponent {
  @Input() block!: ContentBlock;

  get MapImage(): Image {
    return { url: '/api/stories/map.png' };
  }
}
