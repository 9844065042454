import { DatePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { formatDate, isAfter, isBefore, isSameDay, isThisYear } from 'date-fns';
import { format } from 'path';

@Pipe({
  name: 'eventDate',
})
export class EventDatePipe implements PipeTransform {
  datePipe = new DatePipe('nl-NL');

  transform(start: string | Date | null, end?: string | null | Date, alwaysShowStart: boolean = false, ...args: unknown[]): string {
    if (!start) {
      return '';
    }
    const startDate = new Date(start);
    const endDate = end ? new Date(end) : undefined;

    let dateString: Array<string | null> = [];

    if (this.multipleDays(startDate, endDate)) {
      if (!this.isOngoing(startDate, endDate) || alwaysShowStart) {
        dateString.push(this.datePipe.transform(startDate, 'd'));

        if (!this.sameMonth(startDate, endDate) || !isThisYear(startDate)) {
          dateString.push(this.datePipe.transform(startDate, 'MMM'));
        }

        if (!isThisYear(startDate)) {
          dateString.push(this.datePipe.transform(startDate, 'yyyy'));
        }
      }

      const enddateformat = !this.isOngoing(startDate, endDate) ? 'd MMM' : 'd MMMM';
      dateString.push(`t/m ${this.datePipe.transform(endDate, enddateformat)}`);

      if (endDate && !isThisYear(endDate)) {
        dateString.push(this.datePipe.transform(endDate, 'yyyy'));
      }
    }

    if (!this.multipleDays(startDate, endDate)) {
      dateString.push(this.datePipe.transform(startDate, 'd MMMM'));

      if (!isThisYear(startDate)) {
        dateString.push(this.datePipe.transform(startDate, 'yyyy'));
      }
    }

    // DEBUG
    //console.log(
    //  'INPUT DATE',
    //  formatDate(startDate, 'd MMMM yyyy'),
    //  '-',
    //  endDate ? formatDate(endDate, 'd MMMM yyyy') : null,
    //  'OUTPUT DATE',
    //  dateString.filter(Boolean).join(' ')
    //);
    return dateString.filter(Boolean).join(' ');
  }

  isOngoing(startDate: Date, endDate?: Date) {
    const afterStartDate = isAfter(new Date(), startDate);
    const beforeEndDate = endDate ? isBefore(new Date(), endDate!) : true;

    return afterStartDate && beforeEndDate;
  }

  multipleDays(startDate: Date, endDate?: Date) {
    return endDate ? !isSameDay(startDate, endDate!) : false;
  }

  thisYear(startDate: Date, endDate?: Date) {
    return isThisYear(startDate) && (endDate ? isThisYear(endDate!) : true);
  }

  isThisYear(date: string) {
    return isThisYear(date);
  }

  sameMonth(startDate: Date, endDate?: Date) {
    return endDate ? new Date(startDate).getMonth() === new Date(endDate!).getMonth() : false;
  }
}
