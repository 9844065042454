import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromThemes from '../reducers/themes.reducer';
import { getRouterState } from 'src/app/store';
import { Theme } from '../../models';

export const getThemesState = createSelector(fromFeature.getThemeState, (state: fromFeature.ThemesState) => state.themes);

export const getThemesEntities = createSelector(getThemesState, fromThemes.getThemesEntities);
export const getThemesLoaded = createSelector(getThemesState, fromThemes.getThemesLoaded);
export const getThemesLoading = createSelector(getThemesState, fromThemes.getThemesLoading);

export const getAllThemes = createSelector(getThemesEntities, entities => {
  return Object.keys(entities).map(id => entities[id as unknown as number]);
});

export const getOrderedThemes = createSelector(getAllThemes, themes => {
  const orderedPages = [...themes].sort((a, b) => {
    if (a.title < b.title) {
      return -1;
    }
    if (a.title > b.title) {
      return 1;
    }
    return 0;
  });
  return orderedPages;
});

export const getMenuThemes = createSelector(getOrderedThemes, themes => {
  return themes.filter(theme => theme.showInMenu);
});
