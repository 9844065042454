import { createAction, props } from '@ngrx/store';
import { Theme } from '../../models';

export const LoadThemes = createAction('[Theme] Load Themes');
export const LoadThemesSuccess = createAction('[Theme] Load Themes Success', props<{ themes: Theme[] }>());
export const LoadThemesFail = createAction('[Theme] Load Themes Fail', props<{ error: any }>());

export const LoadThemeBySlug = createAction('[Landing] Load Theme by Slug', props<{ slug: string }>());
export const LoadThemeBySlugSuccess = createAction('[Landing] Load Theme by Slug Success', props<{ theme: Theme }>());
export const LoadThemeBySlugFail = createAction('[Landing] Load Theme by Slug Fail', props<{ error: any; slug: string }>());
