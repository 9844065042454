import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Theme } from '../models';

@Injectable({
  providedIn: 'root',
})
export class ThemesService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getThemes() {
    return this.http.get<Theme[]>(`${this.BASE_URL}api/themes`);
  }

  getTheme(id: number) {
    return this.http.get<Theme>(`${this.BASE_URL}api/themes/${id}`);
  }

  getThemeBySlug(slug: string) {
    return this.http.get<Theme>(`${this.BASE_URL}api/themes/${slug}`);
  }
}
