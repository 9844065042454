import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { CookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { Observable } from 'rxjs';
import { ContentBlock } from '../../models';

@Component({
  selector: 'app-content-block-default',
  templateUrl: './content-block-default.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockDefaultComponent {
  @Input() block!: ContentBlock;
  @Input() cookiePreferences!: CookiePreferences;
}
