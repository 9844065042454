<ng-container [formGroup]="group">
  <div
    class="custom-field form-multiple-dates"
    *ngIf="group.get(config.name) as field"
    [class.has--label]="!!config.label"
    [class.has--sub-label]="!!config.subLabel"
    [ngClass]="{
      'custom-field--invalid': showError
    }"
  >
    <input
      matInput
      [formControlName]="config.name"
      [ngClass]="{
        'custom-field--invalid': showError
      }"
      [hidden]="true"
    />

    <span class="label">
      {{ config.label }}
      <span class="mat-form-field-required-marker" *ngIf="isRequired(group.get(config.name))">*</span>
    </span>

    <div class="mt-2">
      <ul class="form-date-list">
        <li class="form-date-list__item" *ngFor="let dateSpan of dateSpans; let index = index">
          <app-event-date-list-item [dateSpan]="dateSpan">
            <button class="button button-icon button--tertiary" type="button" (click)="openRecord(dateSpan, index)">
              <mat-icon>edit</mat-icon>
            </button>
          </app-event-date-list-item>
        </li>

        <li class="text-center mb-2" *ngIf="!dateSpans?.length">
          <span>{{ prefix + '-geen-datum' | fromDictionary }}</span>
        </li>
      </ul>

      <div class="d-flex mt-2">
        <button
          type="button"
          class="button button--tertiary mx-auto"
          (click)="openRecord({ id: 0, start: '', end: '', startTime: '', endTime: '', description: '' }, -1)"
        >
          <span>{{ prefix + '-datum-nieuw' | fromDictionary }}</span>
          <mat-icon>add</mat-icon>
        </button>
      </div>
    </div>

    <div class="custom-field__error-wrapper">
      <mat-error *ngIf="showError">
        {{ field | formError : config.customValidationMessages }}
      </mat-error>
    </div>
  </div>
</ng-container>
