import {
  AfterViewInit,
  Input,
  Component,
  ChangeDetectionStrategy,
  ViewChild,
  ElementRef,
  OnInit,
  inject,
  ChangeDetectorRef,
  AfterContentChecked,
} from '@angular/core';
import { Store } from '@ngrx/store';
import { BehaviorSubject, Observable, Subject, of } from 'rxjs';
import { filter, map, takeUntil, tap } from 'rxjs/operators';
import { Slide, Slider } from '../../models';
import { ContentSlidersState, getSliderEntities, LoadSlider } from '../../store';

//swiper
import { SwiperContainer } from 'swiper/element';
import { A11y, Autoplay, Navigation, Pagination } from 'swiper/modules';
import { SwiperOptions } from 'swiper/types';

@Component({
  selector: 'app-content-slider',
  templateUrl: './content-slider.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentSliderComponent implements OnInit, AfterContentChecked {
  @Input() id!: number;
  @ViewChild('swiper') swiperEl?: ElementRef<SwiperContainer>;
  @ViewChild('swiperThumb') swiperThumbEl?: ElementRef<SwiperContainer>;

  slider$?: Observable<Slider>;

  private loaded = false;

  cd = inject(ChangeDetectorRef);
  store = inject(Store);

  ngAfterContentChecked(): void {
    if (!this.loaded) {
      this.initSlider();
    }
  }

  ngOnInit(): void {
    this.store.dispatch(LoadSlider({ id: this.id }));

    this.slider$ = this.store.select(getSliderEntities).pipe(map(a => a[this.id]));
  }

  initSlider() {
    const that = this;

    const thumbParams: SwiperOptions = {
      modules: [Pagination, Navigation, A11y],
      spaceBetween: 5,
      slidesPerView: 6,
      watchSlidesProgress: true,
      allowTouchMove: false,
      breakpoints: {
        0: {
          slidesPerView: 4,
        },
        600: {
          slidesPerView: 5,
        },
        850: {
          slidesPerView: 6,
        },
        1000: {
          slidesPerView: 7,
        },
        1300: {
          slidesPerView: 8,
        },
      },
    };

    const params: SwiperOptions = {
      modules: [Pagination, Navigation, A11y],
      slidesPerView: 1,
      loop: true,
      speed: 400,
      grabCursor: true,
      thumbs: {
        swiper: this.swiperThumbEl?.nativeElement?.swiper,
      },
      navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
      },
      on: {
        realIndexChange: function () {
          that.cd.detectChanges();
        },
      },
      // freeMode: true,
    };

    if (this.swiperThumbEl?.nativeElement) {
      Object.assign(this.swiperThumbEl.nativeElement, thumbParams);
      this.swiperThumbEl.nativeElement.initialize();
    }

    if (this.swiperEl?.nativeElement) {
      Object.assign(this.swiperEl.nativeElement, params);
      this.swiperEl.nativeElement.initialize();
      this.loaded = true;
    }

    this.cd.detectChanges();
  }

  slidePrev() {
    this.swiperEl?.nativeElement?.swiper?.autoplay?.pause;
    this.swiperEl?.nativeElement?.swiper?.slidePrev();
  }

  slideNext() {
    this.swiperEl?.nativeElement?.swiper?.autoplay?.pause;
    this.swiperEl?.nativeElement?.swiper?.slideNext();
  }

  trackByFn(index: number, item: Slide) {
    return item.id;
  }

  focusTo(element: string) {
    const el = document.querySelector(element);
    if (el && (el as HTMLElement)) {
      (el as HTMLElement).focus();
    }
  }

  get swiper() {
    return this.swiperEl?.nativeElement.swiper;
  }
  get thumbswiper() {
    return this.swiperEl?.nativeElement.swiper;
  }
  get isBeginning() {
    return this.swiper?.isBeginning;
  }
  get isEnd() {
    return this.swiper?.isEnd;
  }
}
