<article>
  <header class="container section-inset-t mt-topbar pb-4">
    <h1 class="fw-bold h3 text-primary text-max-width-xs">Overzicht van beschikbare contentblokken</h1>

    <!-- <nav>
      <ul class="d-flex flex-wrap align-items-center">
        @for (block of blocks; track block) {
          <li class="d-inline-block me-3">
            <a [routerLink]="['/contentblokken']" [fragment]="(block.title | urlRewrite)+'-'+block.id">{{ block.title }}</a>
          </li>
        }
      </ul>
    </nav> -->
  </header>

  <app-content-block-container [blocks]="blocks" [showLabels]="true"></app-content-block-container>
</article>
