<header class="bg-light p-lg-4 theme-header-container">
  <section class="theme-header mt-3 mt-md-0 section-inset-t">
    <main class="container">
      <div class="row flex-column-reverse flex-md-row">
        <div
          class="col-md-6 d-flex flex-column gap-3 mt-3 mt-md-0 pt-4 pt-lg-6"
          [ngClass]="theme.header?.image?.url ? '' : 'mx-auto text-center'"
        >
          <h1
            class="text-primary h2 theme-header__heading"
            [ngClass]="{ 'd-flex flex-column align-items-center gap-2': !theme.header?.image?.url }"
          >
            <span class="chip chip--theme chip--light chip--lg">
              <app-icon [anchor]="theme.icon"></app-icon>
            </span>
            {{ theme.header?.title }}
          </h1>
          <p class="lead" *ngIf="theme.header?.text">
            {{ theme.header?.text }}
          </p>

          <footer *ngIf="quickLinks?.length">
            <span class="lead mb-2 d-block">{{ 'ontdek-meer-thema-label-tekst' | fromDictionary }}</span>
            <div class="d-flex gap-2 flex-column flex-md-row" [class.justify-content-center]="!theme.header?.image?.url">
              <!--<a *ngFor="let item of quickLinks">{{ quickLinks. }}</a>-->
              <a *ngFor="let item of quickLinks" [routerLink]="['.']" [fragment]="'blok-' + item.index" class="button button--light">
                {{ item.blockType + '-anchor-link' | fromDictionary }}
              </a>
            </div>
          </footer>
        </div>
        <figure class="col-md-6" *ngIf="theme.header?.image?.url">
          <app-image
            class="theme-header__image"
            [imageSizes]="[300, 500, 800, 1400]"
            [width]="700"
            [height]="500"
            [alt]="theme.header?.image?.alt || theme.header?.title || ''"
            [image]="theme.header?.image!"
            [loading]="'eager'"
            [sizes]="{ xs: 12, md: 6 }"
            [objectFitMode]="true"
          ></app-image>
        </figure>
      </div>
    </main>
  </section>
  <app-bg-wave
    class="bg-wave--flipped d-block"
    [autoPlay]="true"
    [startPos]="'12s'"
    [bgColor]="'var(--bs-secondary)'"
    [strokeColor]="'transparent'"
  ></app-bg-wave>
</header>
