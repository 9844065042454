import * as exampleContent from './example-content';
import * as exampleMedia from './example-media';

// function returns random item from one of the arrays within exampleData
export const randomExampleContent = (key: string, canAlsoBeUndefined: boolean = false) => {
  const exampleData: { [key: string]: any } = {
    ...exampleContent,
    ...exampleMedia,
  };

  const keys = Object.keys(exampleData);

  // check if key is key of exampleData
  if (!keys.includes(key)) {
    return;
  }

  const content = exampleData[key];

  // if canAlsoBeUndefined is true sometimes return undefined
  if (canAlsoBeUndefined && Math.random() > 0.5) {
    return undefined;
  }

  if (!content && !Array.isArray(content && typeof content !== 'string')) {
    return content;
  }

  if (typeof content === 'string' || (typeof content === 'object' && !Array.isArray(content))) {
    return content;
  }

  return content[Math.floor(Math.random() * content.length)];
};
