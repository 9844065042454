<div class="app">
  <div class="app__inner" [class.anim--in]="loading$ | async" [class.hide--topbar-logo]="(hideTopLogo$ | async) ?? false">
    <a class="skiplink" [href]="skipLinkPath">Ga naar inhoud</a>
    <app-topbar
      (toggleOverlay)="toggleMenu()"
      (closeOverlay)="closeMenu()"
      (openOverlay)="openMenu()"
      (searchOpen)="searchState($event)"
      [theme]="(navTheme$ | async) ?? ''"
      [menuActive]="(menuOpen$ | async) ?? false"
      [menu]="('main-menu' | fromMenu)?.error ? [] : ('main-menu' | fromMenu)"
      [searchActive]="(searchActive$ | async) ?? false"
      [isSignedIn]="(isSignedIn$ | async) ?? false"
      [profile]="profile$ | async"
      [themes]="themes()"
      *ngIf="loaded$ | async"
    >
      <app-search-bar (valueChanges)="searchValueChange($event)" slot="search-bar" [patchValue]="patchSearch$ | async"></app-search-bar>
      <app-search-results
        slot="search-results"
        *ngIf="searchResults$ | async as results"
        [results]="results"
        [loading]="(searchLoading$ | async) ?? false"
        [searchQuery]="searchQuery"
      ></app-search-results>
    </app-topbar>

    <div class="app__router pt-topbar" [class.is--404]="showStatusCode" [@routerFade]="prepareRoute(outlet)" id="content">
      <router-outlet #outlet="outlet"></router-outlet>
    </div>

    <app-footer
      [isSignedIn]="(isSignedIn$ | async) ?? false"
      [footerNav]="'footer-menu' | fromMenu"
      [domain]="domainName"
      *ngIf="loaded$ | async"
      [socialChannels]="socialChannels$ | async"
      [showStatusCode]="showStatusCode"
    ></app-footer>
  </div>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
  <fstr-cookie-notice
    [title]="'cookie-titel' | fromDictionary"
    *ngIf="loaded$ | async"
    [text]="'cookie-tekst' | fromDictionary"
    [buttonAccept]="'cookie-accepteer-knop-tekst' | fromDictionary"
    [buttonReject]="'cookie-afwijzen-knop-tekst' | fromDictionary"
  ></fstr-cookie-notice>
  <app-loader-indicator [loading]="(loading$ | async) ?? false"></app-loader-indicator>
</div>

<app-grid-overlay *ngIf="testGridEnabled"></app-grid-overlay>
