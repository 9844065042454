<!--<button (click)="setRandomData()" class="button button--tertiary">Random!</button>-->

<article>
  <header class="mb-5" *ngIf="block.subtitle || block.title">
    <span *ngIf="block.subtitle" class="fw-bold text-secondary">{{ block.subtitle }}</span>
    <h1 class="h2 text-primary" *ngIf="block.title">
      {{ block.title }}
      <app-icon anchor="icon-squiggle" class="heading-squiggle" *ngIf="block.title"></app-icon>
    </h1>
  </header>
  <ul class="free-mosaic-list row">
    <ng-container *ngFor="let item of blocks">
      <li class="free-mosaic-list__item col-xsm-6 col-xl-4" [ngClass]="'type--' + item.type">
        <ng-container *ngIf="item.block" [ngSwitch]="item.type">
          <app-initiative-card
            [label]="'label-' + item.type | fromDictionary"
            [inverted]="true"
            [showIntro]="false"
            [initiative]="asInitiative(item.block)"
            *ngSwitchCase="'initiatief'"
          ></app-initiative-card>
          <app-story-card
            [label]="'label-' + item.type | fromDictionary"
            [story]="asStory(item.block)"
            *ngSwitchCase="'verhaal'"
          ></app-story-card>
          <app-event-card
            [label]="'label-' + item.type | fromDictionary"
            [event]="asEvent(item.block)"
            *ngSwitchCase="'evenement'"
          ></app-event-card>
        </ng-container>
      </li>
    </ng-container>

    <li class="free-mosaic-list__item type--more col-sm-6 col-xl-4">
      <div class="free-mosaic-block">
        <div class="card bg-gray-100">
          <h2 class="h3 text-primary mb-3">Bekijk meer</h2>
          <menu>
            <li *ngFor="let item of urls" class="mb-2">
              <a
                class="button button-outline button--light-primary w-100 justify-content-start button--sm mosaic-menu-item"
                [routerLink]="item.url"
                [queryParams]="item.queryParams"
              >
                <span
                  class="fw-bold"
                  [ngClass]="
                    ('inspiratie-blok-overzicht-link-label-mobiel-' + item.label | fromDictionary : false) ? 'd-none d-md-inline' : ''
                  "
                >
                  {{ 'inspiratie-blok-overzicht-link-label-' + item.label | fromDictionary }}
                </span>
                <span
                  class="fw-bold d-md-none"
                  *ngIf="'inspiratie-blok-overzicht-link-label-mobiel-' + item.label | fromDictionary : false"
                >
                  {{ 'inspiratie-blok-overzicht-link-label-mobiel-' + item.label | fromDictionary }}
                </span>
              </a>
            </li>
          </menu>
        </div>
      </div>
    </li>
  </ul>
</article>
