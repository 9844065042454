import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';
import { EventDataDateSpan } from 'src/app/events/models';
import { FormDateSpanModalComponent } from '../form-date-span-modal/form-date-span-modal.component';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'app-form-multiple-dates',
  templateUrl: './form-multiple-dates.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormMultipleDatesComponent implements OnInit {
  prefix = 'evenement-formulier';
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  dateSpans: EventDataDateSpan[] = [];
  modelOpened = false;
  showError = false;
  private _unsubscribe = new Subject<void>();

  constructor(private dialog: MatDialog, private cd: ChangeDetectorRef) {}
  ngOnInit(): void {
    // Patch value if exists
    if (this.config.value) {
      this.dateSpans = this.config.value;

      //if (val) {
      //  this.group.get(this.config.name)?.patchValue(val);
      //}
    }

    // Update showerror on statuschange (get doesnt work..)
    this.group
      .get(this.config.name)
      ?.statusChanges.pipe(takeUntil(this._unsubscribe))
      .subscribe(status => {
        const field = this.group.get(this.config.name);
        this.showError = !!(field?.invalid && (field?.touched || field?.dirty)) || this.modelOpened;
        this.cd.detectChanges();
      });
  }

  openRecord(dateSpan: EventDataDateSpan, index: number) {
    const dlg = this.dialog.open(FormDateSpanModalComponent, {
      maxHeight: '80%',
      minWidth: '40vw',
      data: {
        record: dateSpan,
      },
    });

    dlg.afterClosed().subscribe(b => {
      let newSpans = [...this.dateSpans];
      if (b && b !== 'deleted') {
        if (index === -1) {
          newSpans.push(b);
        } else {
          newSpans[index] = b;
        }
      } else if (b && b === 'deleted') {
        newSpans.splice(index, 1);
      }
      this.dateSpans = [...newSpans];

      this.group.get(this.config.name)?.patchValue(this.dateSpans);
      this.group.get(this.config.name)?.markAsDirty();
      this.cd.detectChanges();
    });
  }

  isRequired(ctrl: AbstractControl<any, any> | null) {
    if (!ctrl || !ctrl.validator) {
      return false;
    }
    const validator = ctrl?.validator({} as AbstractControl);
    return validator && validator['required'];
  }
}
