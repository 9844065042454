<section class="content-block-maps">
  <main class="container">
    <div class="text-center text-max-width mx-auto" *ngIf="block.subtitle || block.title">
      <span *ngIf="block.subtitle" class="fw-bold text-secondary">{{ block.subtitle }}</span>
      <h1 class="h2 text-primary" *ngIf="block.title">
        {{ block.title }}
      </h1>
      <a class="button button--primary map-cta-button" [routerLink]="['/verhalen', 'kaart']">
        {{ 'kaart-cta-knop-tekst' | fromDictionary }}
      </a>
    </div>

    <div class="mt-1">
      <a class="map-cta__figure" [routerLink]="['/verhalen', 'kaart']">
        <app-image
          [imageSizes]="[350, 640]"
          [width]="640"
          [height]="300"
          alt="Google Map"
          [image]="MapImage"
          [loading]="'lazy'"
          [objectFitMode]="true"
        ></app-image>
      </a>
    </div>
  </main>
</section>
