import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as themeActions from '../actions/themes.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ThemesService } from '../../services';

@Injectable()
export class ThemesEffects {
  constructor(private actions$: Actions, private ThemeService: ThemesService) {}

  LoadThemes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(themeActions.LoadThemes),
      switchMap(() =>
        this.ThemeService.getThemes().pipe(
          map(themes => themeActions.LoadThemesSuccess({ themes })),
          catchError(error => of(themeActions.LoadThemesFail({ error })))
        )
      )
    );
  });

  LoadBySlug$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(themeActions.LoadThemeBySlug),
      switchMap(({ slug }) =>
        this.ThemeService.getThemeBySlug(slug).pipe(
          map(theme => themeActions.LoadThemeBySlugSuccess({ theme })),
          catchError(error => of(themeActions.LoadThemeBySlugFail({ error, slug })))
        )
      )
    );
  });
}
