import { ChangeDetectionStrategy, Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';

@Component({
  selector: 'app-contact-card',
  templateUrl: './contact-card.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContactCardComponent implements OnInit, OnChanges {
  @Input() title?: string;
  @Input() subtitle?: string;
  @Input() name?: string | null;
  @Input() label?: string | null;
  @Input() email?: string | null;
  @Input() imageUrl?: string | null;
  @Input() logoUrl?: string | null;
  @Input() contact?: any;

  private dict = new FromDictionaryPipe(this.lang);

  constructor(private lang: LanguageService) {}

  ngOnInit() {
    this.checkContact();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.checkContact();
  }

  checkContact() {
    if (!this.email) {
      this.label = this.dict.transform('initiatief-default-contact-persoon-functie');
      this.email = this.dict.transform('initiatief-default-contact-persoon-email');
      this.name = this.dict.transform('initiatief-default-contact-persoon-naam');
      this.imageUrl = this.dict.transform('initiatief-default-contact-persoon-afbeelding');
    }
  }
}
