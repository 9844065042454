<article class="date-list-item" *ngIf="dateSpan" [class.is--past]="eventInPast()">
  <header class="date-list-item__header">
    <div class="date-list-item__header__inner">
      <div class="me-3 d-flex align-items-center gap-2" *ngIf="dateSpan.start || dateSpan.end">
        <span class="chip chip--primary me-1">
          <app-icon anchor="icon-calendar"></app-icon>
        </span>

        <span>
          {{ dateSpan.start | eventDate : dateSpan.end }}
        </span>
      </div>

      <div *ngIf="dateSpan.startTime || dateSpan.endTime" class="d-flex gap-2 align-items-center">
        <span class="chip chip--primary me-1">
          <app-icon anchor="icon-clock"></app-icon>
        </span>

        <span *ngIf="dateSpan.startTime">
          {{ dateSpan.startTime }}
        </span>

        <span *ngIf="dateSpan.endTime">- {{ dateSpan.endTime }}</span>
      </div>
    </div>

    <div class="ms-auto d-flex align-items-center gap-2">
      <span class="badge bg-gray-300 text-dark" *ngIf="eventInPast()">{{ 'event-verleden-tijd' | fromDictionary }}</span>
      <ng-content></ng-content>
    </div>
  </header>

  <main *ngIf="dateSpan.description">
    <p class="mb-0">{{ dateSpan.description }}</p>
  </main>
</article>
