import { Injectable } from '@angular/core';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom, map } from 'rxjs/operators';

import * as fromStore from '../store';
import * as fromRoot from '../../store';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class ThemeDetailGuard {
  constructor(private store: Store<fromStore.ThemesState>) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(fromStore.getSelectedThemeLoaded).pipe(
      withLatestFrom(this.store.select(fromRoot.getRouterState)),
      tap(([loaded, router]) => {
        if (!loaded) {
          const slug = router.state.params['slug'] || router.state.data['slug'];
          this.store.dispatch(fromStore.LoadThemeBySlug({ slug }));
        }
      }),
      filter(([loaded, router]) => loaded),
      map(([loaded, router]) => loaded),
      take(1)
    );
  }
}
