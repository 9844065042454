import { isPlatformBrowser } from '@angular/common';
import { HttpClient } from '@angular/common/http';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  InjectionToken,
  OnInit,
  PLATFORM_ID,
  ViewChild,
} from '@angular/core';
import { AbstractControl, FormBuilder, UntypedFormGroup } from '@angular/forms';
import { GoogleMap, MapGeocoder } from '@angular/google-maps';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';
import { catchError, debounceTime, distinct, map, Observable, of, pairwise, Subject, take, takeUntil, tap } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Geolocation } from '../../../core/models';
import { ijsselGeolocation } from 'src/app/utility/geolocation.utility';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatLegacyCheckboxChange } from '@angular/material/legacy-checkbox';

@Component({
  selector: 'app-form-location',
  templateUrl: './form-location.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class FormLocationComponent implements OnInit {
  config!: DynamicFormField;
  group!: UntypedFormGroup;

  apiLoaded: Observable<boolean> = of(false);

  options: google.maps.MapOptions = {
    center: { lat: 52.2739187, lng: 5.969158 },
    zoom: 9,
    disableDefaultUI: true,
    zoomControl: true,
  };

  localForm = this.fb.group({
    query: [''],
  });

  @ViewChild('maps') maps?: GoogleMap;

  markerOptions: google.maps.MarkerOptions = { draggable: true };
  activeMarker?: Geolocation;
  markers: Geolocation[] = [];
  locationLabel?: string;

  ijsselGeolocation = ijsselGeolocation;

  locationResults: google.maps.GeocoderResult[] = [];
  private _unsubscribe$ = new Subject<void>();

  markerIcon?: google.maps.Symbol;
  //markerLabel?: google.maps.MarkerLabel;

  mapMoved = false;

  constructor(
    private httpClient: HttpClient,
    private geocoder: MapGeocoder,
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>
  ) {}

  get useDefaultLocationControl() {
    return this.group.get('entireArea');
  }

  ngOnInit(): void {
    if (isPlatformBrowser(this.platformId)) {
      if (window['google']?.['maps']) {
        this.apiLoaded = of(true);
      } else {
        this.apiLoaded = this.httpClient
          .jsonp(`https://maps.googleapis.com/maps/api/js?key=${environment.mapsApiKey}&region=NL&language=NL`, 'callback')
          .pipe(
            map(() => true),
            tap(() => {
              this.markerIcon = {
                path: 'M32.7273 16.3636C32.7273 29.0909 16.3636 40 16.3636 40C16.3636 40 0 29.0909 0 16.3636C6.46697e-08 12.0237 1.72402 7.86158 4.7928 4.7928C7.86158 1.72402 12.0237 0 16.3636 0C20.7035 0 24.8657 1.72402 27.9345 4.7928C31.0033 7.86158 32.7273 12.0237 32.7273 16.3636Z', // Circle with a stem
                fillColor: '#0f729c',
                fillOpacity: 1,
                strokeOpacity: 0,
                scale: 1,
                anchor: new google.maps.Point(16, 40), // Position the anchor at the bottom of the pin
                labelOrigin: new google.maps.Point(16, 16),
              };
            }),
            catchError(e => {
              console.error('Google map not loaded', e);
              return of(false);
            })
          );
      }
    }

    // Patch value if exists
    if (this.config.value) {
      let val: Geolocation[] = this.config.value;

      // check if value is array (for older versions)
      if (!Array.isArray(val)) {
        val = [val];
      }

      if (val) {
        this.group.get(this.config.name)?.patchValue(val);
      }

      //if (val) {
      //  this.group.get(this.config.name)?.patchValue(val);

      //  this.marker = {
      //    lat: val?.lat,
      //    lng: val?.lng,
      //  };
      //  this.locationLabel = val?.label;

      //  this.searchForm.get('query')?.patchValue(val?.label, { emitEvent: false, onlySelf: true });

      //  this.cd.detectChanges();
      //}
    }

    this.localForm
      .get('query')
      ?.valueChanges.pipe(debounceTime(200), takeUntil(this._unsubscribe$))
      .subscribe((val: any) => {
        this.getLocationFromString(val?.['formatted_address'] || val);
      });
  }

  // from clickevents of map
  getLocation(clickedLocation?: google.maps.LatLng | google.maps.LatLngLiteral, setFirstResult: boolean = false) {
    if (!clickedLocation) {
      return;
    }

    this.geocoder
      .geocode({
        location: clickedLocation,
      })
      .pipe(take(1))
      .subscribe(({ results }) => {
        this.locationResults = results;

        if (setFirstResult && results.length) {
          //this.locationLabel = results[0].address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name; // get only city name

          //this.searchForm.get('query')?.patchValue(this.locationLabel, { emitEvent: false, onlySelf: true });
          const loc = results[0];

          if (this.activeMarker) {
            const city = loc.address_components.filter(ac => ~ac.types.indexOf('locality'))[0].long_name; // get only city name;
            this.activeMarker.label = city || loc.formatted_address;
          }

          //this.updateFormValue();
        }
        this.cd.detectChanges();
      });
  }

  // chose one of autocomplete options
  addLocationFromResults(location: google.maps.GeocoderResult) {
    const result = location.geometry.location.toJSON();

    if (location) {
      //this.marker = result;
      //this.locationLabel = location.formatted_address;
      //this.searchForm.get('query')?.patchValue(this.locationLabel, { emitEvent: false, onlySelf: true });

      this.activeMarker = {
        id: (this.group.get(this.config.name)?.value?.length || 0) + 1,
        lat: result.lat,
        lng: result.lng,
        label: location.formatted_address,
      };

      this.maps?.googleMap?.setCenter(result);

      this.updateFormValue();
      this.localForm.get('query')?.setValue('');
      this.cd.detectChanges();
    }
  }

  // on searchform change
  getLocationFromString(query?: string | null) {
    const q = query || this.localForm.get('query')?.value;

    if (q) {
      this.geocoder
        .geocode({
          address: q,
        })
        .pipe(take(1))
        .subscribe(({ results }) => {
          this.locationResults = results;

          this.cd.detectChanges();
          //this.geoCodeResult(results);
        });
    }
  }

  // mapClick
  setMarker(event: google.maps.MapMouseEvent) {
    const coords = event.latLng?.toJSON();

    if (coords) {
      this.activeMarker = {
        id: 0,
        lat: coords.lat,
        lng: coords.lng,
        label: '',
      };

      this.getLocation(coords, true);
      this.updateFormValue();
    }
  }

  updateMarker(event: google.maps.MapMouseEvent, index: number) {
    const field = this.group.get(this.config.name);
    const currentValue: Geolocation[] = field?.value || [];

    const coords = event.latLng?.toJSON();

    if (index !== -1 && coords) {
      currentValue[index] = {
        ...currentValue[index],
        lat: coords.lat,
        lng: coords.lng,
      };

      this.activeMarker = currentValue[index];
      this.getLocation(coords, true);

      field?.setValue(currentValue);
    }
  }

  updateFormValue() {
    const field = this.group.get(this.config.name);
    const currentValue = field?.value || [];

    field?.setValue([...currentValue, this.activeMarker]);
  }

  displayFn(result: google.maps.GeocoderResult | string): string {
    if ((<google.maps.GeocoderResult>result).hasOwnProperty('formatted_address')) {
      return (<google.maps.GeocoderResult>result)?.formatted_address;
    }
    return <string>result;
  }

  isRequired(field: AbstractControl<any, any> | null) {
    if (!field || !field.validator) {
      return false;
    }
    const validator = field?.validator({} as AbstractControl);
    return validator && validator['required'];
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  markerLocation(marker: Geolocation): google.maps.LatLngLiteral | google.maps.LatLng {
    return {
      lat: +marker.lat,
      lng: +marker.lng,
    };
  }

  getMarkerLabel(index: number): google.maps.MarkerLabel {
    return {
      text: index.toString(),
      color: 'white',
      fontWeight: 'bold',
    };
  }

  removeLocation(marker: Geolocation, index: number) {
    const field = this.group.get(this.config.name);
    const currentValue = field?.value || [];

    currentValue.splice(index, 1);

    field?.setValue([...currentValue]);
  }

  centerMapToFitAllMarkers() {
    const markers: Geolocation[] = this.group.get(this.config.name)?.value;
    if (markers.length) {
      const bounds = new google.maps.LatLngBounds();

      markers.forEach(marker => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng));
      });

      this.maps?.googleMap?.fitBounds(bounds);
      this.mapMoved = false;
    }
  }

  removeAll() {
    this.group.get(this.config.name)?.setValue([]);
  }

  entireAreaChange(val: MatLegacyCheckboxChange) {
    if (val.checked) {
      this.localForm.get('query')?.disable();
      this.activeMarker = { ...ijsselGeolocation };
      this.group.get(this.config.name)?.setValue([this.activeMarker]);
    } else {
      this.localForm.get('query')?.enable();
      this.group.get(this.config.name)?.setValue([]);
    }
    this.cd.detectChanges();
  }
}
