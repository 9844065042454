import * as exampleEntities from './example-entities';

// return random items from exampleEntities arrays
export const randomExampleEntities = (key: string) => {
  const entities: { [key: string]: any } = { ...exampleEntities };

  console.log(entities, exampleEntities);

  // check if key is key of exampleEntities
  if (!Object.keys(entities).includes(key)) {
    console.log(`${key} not found in exampleEntities`);
    return;
  }

  // shuffle the array
  const shuffledArray = entities[key].sort(() => Math.random() - 0.5);
  return shuffledArray;
};
