import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { isPast } from 'date-fns';
import { EventDataDateSpan } from 'src/app/events/models';

@Component({
  selector: 'app-event-date-list-item',
  templateUrl: './event-date-list-item.component.html',
  styleUrls: ['./event-date-list-item.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class EventDateListItemComponent {
  @Input({ required: true }) dateSpan?: EventDataDateSpan;

  dateFormat(date: string | Date, type: 'start' | 'end'): string {
    let format = 'd';

    // check if the month of the startdate is different from the enddate
    if (type === 'start' && this.isMultipleDays && this.dateSpan?.start && this.dateSpan?.end) {
      const monthIsSame = new Date(this.dateSpan.start).getMonth() === new Date(this.dateSpan.end).getMonth();

      if (!monthIsSame) {
        format += ' MMM';
      }
    } else if (type === 'end' || (type === 'start' && !this.isMultipleDays)) {
      format += ' MMM';
    }

    // if date is not this year add
    if (new Date(date).getFullYear() !== new Date().getFullYear()) {
      return format + ' yyyy';
    }

    return format;
  }

  get isMultipleDays(): boolean {
    return this.dateSpan?.start !== this.dateSpan?.end;
  }

  eventInPast() {
    if (!this.dateSpan || !this.dateSpan?.end || !this.dateSpan?.start) {
      return false;
    }
    return isPast(new Date(this.dateSpan?.end || this.dateSpan?.start));
  }
}
