import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { HomeModule } from '../home/home.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
import { ThemeRoutingModule } from './themes-routing.module';

@NgModule({
  imports: [
    CommonModule,
    DictionaryNgrxModule,
    HomeModule,
    ThemeRoutingModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
})
export class ThemesModule {}
