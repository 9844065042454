import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ContentBlock } from '../../models';
import { randomExampleContent } from 'src/app/styleguide/example-data/example-data-utils';
import config from '../../utils/CMS-config.json';
import { randomExampleEntities } from 'src/app/styleguide/example-data/example-entities-function';

@Component({
  selector: 'app-content-block-overview',
  templateUrl: './content-block-overview.component.html',
  styleUrls: ['./content-block-overview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ContentBlockOverviewComponent {
  blocks: ContentBlock[] = [];

  constructor() {
    const allOptions: {
      name: string;
      value: string;
    }[] = [...config];

    this.blocks = Array.from({ length: allOptions.length }, (_, i) => {
      const blockType = allOptions[i].value;
      const isNotFirstOfBlocktype = allOptions.findIndex(a => a.value === blockType) !== i;

      let content = randomExampleEntities('baseContent') || [];
      console.log('test', content);
      if (blockType === 'block-events') {
        content = randomExampleEntities('events');
      } else if (blockType === 'block-initiatives') {
        content = randomExampleEntities('initiatives');
      }

      return {
        id: i,
        title: randomExampleContent('title'),
        // blockType: 'block-default',
        subtitle: randomExampleContent('subtitle'),
        blockType,
        cmsblockTypeTitle: allOptions[i].name,
        intro: randomExampleContent('paragraph', true),
        text: randomExampleContent('wysiwygSimpleShort', true),
        image: randomExampleContent('image', true),
        video: randomExampleContent('video', true),
        button: randomExampleContent('button', true),
        secondaryButton: randomExampleContent('button', true),
        backgroundTheme: randomExampleContent('backgroundTheme', true)?.value,
        content,
        // button: { url: 'https://www.google.com', text: 'Google' },
        // TODO randomExamplecontent array voorbeeld (niewsberichten)
      };
    });
  }

  // TODO specific contentblock fields.
}
