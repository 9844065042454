import { Component, OnInit, Inject, PLATFORM_ID, Renderer2, OnDestroy, InjectionToken, ChangeDetectorRef, inject } from '@angular/core';

import { isPlatformBrowser, DOCUMENT, ViewportScroller } from '@angular/common';
import { Router, NavigationEnd, RouterOutlet, NavigationStart, NavigationCancel, NavigationSkipped, Scroll } from '@angular/router';
import { Store } from '@ngrx/store';
import { DictionaryState, getDictionaryLoaded } from '@teamfoster/sdk/dictionary-ngrx';
import { Observable, BehaviorSubject, timer, of, Subject, combineLatest, fromEvent, merge, Subscription } from 'rxjs';
import { BaseContent, ContentPage } from './content/models';

import * as navActions from './store/actions/nav.action';
import * as navSelectors from './store/selectors/nav.selector';
import { tap, take, filter, withLatestFrom, takeUntil, map, switchMap, distinctUntilChanged, share } from 'rxjs/operators';
import { routerFade } from './animations';
import { ChangeDetectionStrategy } from '@angular/core';
import { CookieConfig, CookieConfigService, CookiePreferences, getCookiePreferences } from '@teamfoster/sdk/cookie-ngrx';
import { getRouterState, RouterStateUrl } from './store';
import { RouterReducerState } from '@ngrx/router-store';
import { getMenuLoaded } from '@teamfoster/sdk/menu-ngrx';
import { SocialChannel } from './social-media/models';
import { getOrderedSocialChannels } from './social-media/store';
import { getAuthSignedIn, getAuthUser } from './auth/store';
import { SearchResult } from './search/models';
import { getOrderedSearchResults, getSearchPatchQuery, getSearchResultsLoading, LoadSearchResults } from './search/store';
import { LandingPage } from './landing-pages/models';
import { Profile } from './auth/models/profile.model';
import { getAllThemes, getMenuThemes, getOrderedThemes } from './themes/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  animations: [routerFade],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppComponent implements OnInit, OnDestroy {
  store = inject(Store);
  loading$ = new BehaviorSubject<boolean>(true);
  loaded$!: Observable<boolean>;
  socialChannels$!: Observable<SocialChannel[]>;
  routeState$!: Observable<RouterReducerState<RouterStateUrl>>;
  menuOpen$!: Observable<boolean>;
  searchActive$!: Observable<boolean>;
  isSignedIn$!: Observable<boolean>;
  profile$!: Observable<any>;
  cookiePrefs$!: Observable<CookiePreferences | null>;
  domainName: string = '';
  testGridEnabled = false;
  routerAnimationState = '';
  navTheme$!: Observable<string>;
  searchResults$!: Observable<Record<string, SearchResult<BaseContent | LandingPage>[]>>;
  searchLoading$!: Observable<boolean>;
  patchSearch$!: Observable<string | undefined>;

  hideTopLogo$!: Observable<boolean>;
  resizeObservable$?: Subscription;

  skipLinkPath = '';
  showStatusCode: boolean = false;

  searchQuery?: string;
  themes = this.store.selectSignal(getMenuThemes); // filter

  private previousPath = '';
  private _unsubscribe$ = new Subject<void>();
  private viewportScroller = inject(ViewportScroller);

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId: InjectionToken<Object>,
    private router: Router,
    private renderer: Renderer2,
    @Inject(CookieConfigService) private cookieConfig: CookieConfig,
    private cd: ChangeDetectorRef,
    viewport: ViewportScroller
  ) {
    viewport.setOffset([0, 150]);
  }

  ngOnInit(): void {
    this.cookiePrefs$ = this.store.select(getCookiePreferences);
    this.loaded$ = combineLatest([this.store.select(getDictionaryLoaded), this.store.select(getMenuLoaded)]).pipe(
      map(([dictionaryLoaded, menuLoaded]) => dictionaryLoaded && menuLoaded)
    );

    // Overlay handlers
    this.menuOpen$ = this.store.select(navSelectors.getMenuOpen).pipe(tap(a => this.updateRoot(a)));
    this.searchActive$ = this.store.select(navSelectors.getSearchActive);
    this.navTheme$ = this.store.select(navSelectors.getTheme);
    this.searchResults$ = this.store.select(getOrderedSearchResults);
    this.searchLoading$ = this.store.select(getSearchResultsLoading);
    this.patchSearch$ = this.store.select(getSearchPatchQuery);
    this.hideTopLogo$ = this.store.select(navSelectors.getLogoHide);
    this.isSignedIn$ = this.store.select(getAuthSignedIn);
    this.socialChannels$ = this.store.select(getOrderedSocialChannels);
    this.menuOpen$ = this.store.select(navSelectors.getMenuOpen).pipe(tap(a => this.updateRoot(a)));
    this.routeState$ = this.store.select(getRouterState);
    this.profile$ = this.store.select(getAuthUser);
    this.router.events.pipe(withLatestFrom(this.cookiePrefs$, this.routeState$)).subscribe(([event, cookies, state]) => {
      if (event instanceof NavigationStart) {
        this.loading$.next(true);
        this.showStatusCode = (<NavigationStart>event).url === '/404';
        this.cd.detectChanges();
      } else if (event instanceof NavigationEnd) {
        this.loading$.next(false);
        this.showStatusCode = (<NavigationEnd>event).url === '/404';
        this.cd.detectChanges();
      }

      if (event instanceof Scroll && !(event.routerEvent instanceof NavigationCancel)) {
        this.handleScroll(event, state);
      }

      if (!this.router.url.endsWith('#content')) {
        this.skipLinkPath = `${this.router.url}#content`;
      }

      if (
        isPlatformBrowser(this.platformId) &&
        event instanceof NavigationStart &&
        (event as NavigationStart).navigationTrigger === 'imperative'
      ) {
        if (!state?.state?.queryParams) {
          window.scrollTo(0, 0);
        }
      }

      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd && cookies?.analytical) {
        if ((<any>window).gtag) {
          (<any>window).gtag('config', this.cookieConfig.analyticsCode, { page_path: event.urlAfterRedirects, anonymize_ip: true });
        }
      }
    });

    this.showStatusCode = this.router.url === '/404';

    if (isPlatformBrowser(this.platformId)) {
      this.domainName = this.document.location.hostname;
      this.document.querySelector('body').classList.add('set--in');
      this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);

      this.resizeObservable$ = fromEvent(window, 'resize').subscribe(evt => {
        this.document.documentElement.style.setProperty('--vh', `${window.innerHeight * 0.01}px`);
      });

      // Shortcuts
      const shortcutKeys = ['Escape'];
      fromEvent<KeyboardEvent>(document, 'keydown')
        .pipe(filter((event: KeyboardEvent) => shortcutKeys.includes(event.code)))
        .subscribe(a => {
          if (a.code === 'Escape') {
            this.closeMenu();
            this.store.dispatch(navActions.CloseSearch());
          }
        });
    }
  }

  searchState(open: boolean) {
    if (open) {
      this.store.dispatch(navActions.OpenSearch());
    } else {
      this.store.dispatch(navActions.CloseSearch());
    }
  }

  toggleMenu() {
    this.store.dispatch(navActions.ToggleMenu());
  }

  closeMenu() {
    this.store.dispatch(navActions.CloseMenu());
  }

  openMenu() {
    this.store.dispatch(navActions.OpenMenu());
  }

  prepareRoute(outlet: RouterOutlet) {
    return outlet && outlet.activatedRouteData;
  }

  searchValueChange(q: string) {
    this.searchQuery = q;
    this.store.dispatch(LoadSearchResults({ q }));
  }

  private updateRoot(menuActive: boolean) {
    menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  handleScroll(event: Scroll, state: RouterReducerState<RouterStateUrl>) {
    const urlFragment = event.anchor;
    // if we can smooth scroll to the fragment, do it. Otherwise fallback on build in anchor scrolling
    if (event.routerEvent.id > 1 && urlFragment && document.getElementById(urlFragment)) {
      this.smoothScroll(document.getElementById(urlFragment));
      return;
    }
    if (event.anchor) {
      // If anchor exists, scroll to anchor
      this.viewportScroller.scrollToAnchor(event.anchor);
    } else if (event.position) {
      // Scroll to the saved position
      this.viewportScroller.scrollToPosition(event.position);
    } else {
      // Scroll to the top
      this.viewportScroller.scrollToPosition([0, 0]);
    }
  }

  private smoothScroll(targetElement: HTMLElement | null): void {
    if (!targetElement) {
      return;
    }
    const yOffset = -120; // Your offset value
    const yPosition = targetElement.getBoundingClientRect().top + window.scrollY + yOffset;

    setTimeout(() => {
      window.scrollTo({
        top: yPosition,
        behavior: 'smooth',
      });
    }, 0);
  }
}
