import { OnInit, Component, Inject, AfterViewInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { Subject } from 'rxjs';
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from '@angular/material/dialog';
import { DynamicFormField } from '@teamfoster/sdk/legacy-dynamic-form';
import { FromDictionaryPipe, LanguageService } from '@teamfoster/sdk/dictionary-ngrx';
import { Validators } from '@angular/forms';
import { EventDataDateSpan } from 'src/app/events/models';

@Component({
  selector: 'app-form-date-span-modal',
  templateUrl: './form-date-span-modal.component.html',
})
export class FormDateSpanModalComponent implements OnInit, OnDestroy, AfterViewInit {
  id: any;
  prefix = 'evenement-formulier';
  private dict = new FromDictionaryPipe(this.lang);

  record?: EventDataDateSpan;
  formFields!: DynamicFormField[] | null;

  _unsubscribe = new Subject<void>();

  constructor(
    public dialogRef: MatDialogRef<FormDateSpanModalComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogService: MatDialog,
    private cdRef: ChangeDetectorRef,
    private lang: LanguageService
  ) {
    if (this.data) {
      this.record = this.data.record;
      this.id = this.record?.id;
    }
  }

  ngOnInit() {
    this.formFields = [
      {
        label: this.dict.transform(`${this.prefix}-veld-label-datum`),
        name: 'date',
        validators: [Validators.required],
        startdatePlaceholder: this.dict.transform(`${this.prefix}-veld-placeholder-startdatum`, false),
        enddatePlaceholder: this.dict.transform(`${this.prefix}-veld-placeholder-einddatum`, false),
        hint: this.dict.transform(`${this.prefix}-veld-hint-datum`, false),
        value: this.record?.start
          ? {
              start: this.record?.start,
              end: this.record?.end,
            }
          : undefined,
        fieldType: 'date',
        order: 0,
        fieldset: 'event',
        customValidationMessages: {},
      },
      {
        label: this.dict.transform(`${this.prefix}-veld-label-starttijd`),
        name: 'startTime',
        validators: [],
        value: this.record?.startTime,
        fieldType: 'input',
        inputType: 'time',
        order: 0,
        fieldset: 'event',
        cssClass: 'col-md-6',
      },
      {
        label: this.dict.transform(`${this.prefix}-veld-label-eindtijd`),
        name: 'endTime',
        validators: [],
        value: this.record?.endTime,
        fieldType: 'input',
        inputType: 'time',
        order: 0,
        fieldset: 'event',
        cssClass: 'col-md-6',
      },
      {
        label: this.dict.transform(`${this.prefix}-veld-label-omschrijving`),
        name: 'description',
        validators: [],
        value: this.record?.description,
        fieldType: 'textarea',
        order: 0,
        fieldset: 'event',
        cssClass: 'col-md-12',
      },
    ];
  }

  ngAfterViewInit(): void {
    this.cdRef.detectChanges();
  }

  save(data: any) {
    const startDateAndTime = new Date(data.date.start);
    const endDateAndTime = new Date(data.date.end || data.date.start);

    if (data.date.start && data.startTime) {
      const [hours, minutes] = data.startTime.split(':');
      startDateAndTime.setHours(parseInt(hours));
      startDateAndTime.setMinutes(parseInt(minutes));
    }

    if (data.endTime) {
      const [hours, minutes] = data.endTime.split(':');
      endDateAndTime.setHours(parseInt(hours));
      endDateAndTime.setMinutes(parseInt(minutes));
    }

    const record: EventDataDateSpan = {
      id: this.id,
      start: startDateAndTime.toISOString(),
      end: endDateAndTime.toISOString(),
      startTime: data.startTime,
      endTime: data.endTime,
      description: data.description,
    };

    this.dialogRef.close(record);
  }

  deleteItem() {
    this.dialogRef.close('deleted');
  }

  ngOnDestroy(): void {
    this._unsubscribe.next();
    this._unsubscribe.complete();
  }
}
