import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { TextUtilityModule } from '@teamfoster/sdk/text-utility';
import { CoreModule } from '../core/core.module';
import { CookieNgrxModule } from '@teamfoster/sdk/cookie-ngrx';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ContentBlockContainerComponent } from './containers/content-block-container/content-block-container/content-block-container.component';
import { ContentBlockDefaultComponent } from './components/content-block-default/content-block-default.component';
import { MosaicBlockComponent } from './components/mosaic-block/mosaic-block.component';
import { ContentBlockCtaComponent } from './components/content-block-cta/content-block-cta.component';
import { ImageModule } from '@teamfoster/sdk/image';
import { ContentBlockMapsComponent } from './components/content-block-maps/content-block-maps.component';
import { ContentBlockStoriesComponent } from './components/content-block-stories/content-block-stories.component';
import { ContentBlockEventsComponent } from './components/content-block-events/content-block-events.component';
import { ContentBlockInitiativesComponent } from './components/content-block-initiatives/content-block-initiatives.component';
import { ContentBlocksRoutingModule } from './content-blocks-routing.module';
import { ContentBlockOverviewComponent } from './containers/content-block-overview/content-block-overview.component';

export const moduleExports = [ContentBlockContainerComponent];

@NgModule({
  declarations: [
    ...moduleExports,
    ContentBlockDefaultComponent,
    MosaicBlockComponent,
    ContentBlockCtaComponent,
    ContentBlockMapsComponent,
    ContentBlockOverviewComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    TextUtilityModule,
    CoreModule,
    CookieNgrxModule,
    DictionaryNgrxModule,
    ImageModule,
    ContentBlockStoriesComponent,
    ContentBlockEventsComponent,
    ContentBlockInitiativesComponent,
    ContentBlocksRoutingModule,
  ],
  exports: [...moduleExports],
})
export class ContentBlocksModule {}
