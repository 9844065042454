import * as fromPages from './themes.reducer';
import * as fromDetail from './themes-detail.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Themes';

export interface ThemesState {
  themes: fromPages.ThemesState;
  detail: fromDetail.ThemesDetailState;
}

export const reducers: ActionReducerMap<ThemesState> = {
  themes: fromPages.ThemeReducer,
  detail: fromDetail.ThemesDetailReducer,
};

export const getThemeState = createFeatureSelector<ThemesState>(featureKey);

export const metaReducers: MetaReducer<ThemesState>[] = !environment.production ? [] : [];
