import { ChangeDetectionStrategy, Component, computed, inject } from '@angular/core';
import { ThemeHeaderComponent } from '../../components/theme-header/theme-header.component';
import { Store } from '@ngrx/store';
import { getAllThemes, getSelectedTheme } from '../../store';
import { ContentBlocksModule } from '../../../content-blocks/content-blocks.module';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'app-theme-landing-page',
  templateUrl: './theme-landing-page.component.html',
  styleUrls: ['./theme-landing-page.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, ThemeHeaderComponent, ContentBlocksModule],
})
export class ThemeLandingPageComponent {
  store = inject(Store);

  theme = this.store.selectSignal(getSelectedTheme);
}
