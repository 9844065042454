import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './containers/home/home.component';
import { HomeRoutingModule } from './home-routing.module';
import { CoreModule } from '../core/core.module';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { ThemeHeaderComponent } from '../themes/components/theme-header/theme-header.component';

@NgModule({
  imports: [CommonModule, HomeRoutingModule, CoreModule, DictionaryNgrxModule],
  declarations: [HomeComponent],
})
export class HomeModule {}
