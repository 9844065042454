import { ChangeDetectionStrategy, Component, CUSTOM_ELEMENTS_SCHEMA, Input } from '@angular/core';
import { ContentBlock } from '../../models';
import { CommonModule } from '@angular/common';
import { SliderV2Component } from 'src/app/core/components/slider-v2/slider-v2.component';
import { Story } from 'src/app/stories/models';
import { DictionaryNgrxModule } from '@teamfoster/sdk/dictionary-ngrx';
import { StoryCardComponent } from 'src/app/core/components/story-card/story-card.component';
import { CoreModule } from 'src/app/core/core.module';
import { BaseContent } from 'src/app/content/models';
import { Theme } from 'src/app/themes/models';

@Component({
  selector: 'app-content-block-stories',
  templateUrl: './content-block-stories.component.html',
  styleUrls: ['./content-block-stories.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [CommonModule, SliderV2Component, DictionaryNgrxModule, CoreModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ContentBlockStoriesComponent {
  @Input({ required: true }) block!: ContentBlock;
  @Input() content: BaseContent[] = [];
  @Input() theme?: Theme;

  get moreUrl() {
    let queryParams = {};
    if (this.theme !== undefined) {
      queryParams = { thema: this.theme.id };
    }
    return {
      url: ['/verhalen'],
      queryParams,
    };
  }

  get stories(): Story[] {
    return this.content as Story[];
  }
}
