import { createReducer, on } from '@ngrx/store';
import { Theme } from '../../models';
import * as actions from '../actions/themes.action';
import * as utility from '../../../utility';

export interface ThemesDetailState {
  entities: { [slug: string]: Theme };
  loaded: string[];
  loading: string[];
  error?: any;
}

export const initialState: ThemesDetailState = {
  entities: {},
  loaded: [],
  loading: [],
};

export const ThemesDetailReducer = createReducer(
  initialState,
  on(actions.LoadThemeBySlug, (state: ThemesDetailState, { slug }) => {
    slug = slug?.toLowerCase().trim();
    return {
      ...state,
      loading: [...state.loading, slug],
    };
  }),
  on(actions.LoadThemeBySlugSuccess, (state: ThemesDetailState, { theme }) => {
    const entities = { ...state.entities, [theme.slug.toLowerCase().trim()]: theme };
    return {
      ...state,
      entities,
      loading: state.loading.filter(slug => slug !== theme.slug.toLowerCase().trim()),
      loaded: [...state.loaded, theme.slug.toLowerCase().trim()],
    };
  }),
  on(actions.LoadThemeBySlugFail, (state: ThemesDetailState, { error, slug }) => {
    slug = slug?.toLowerCase().trim();
    return {
      ...state,
      loading: state.loading.filter(slug => slug !== slug),
      loaded: state.loaded.filter(slug => slug !== slug),
      error,
    };
  })
);

export const getThemesDetailEntities = (state: ThemesDetailState) => state.entities;
export const getThemesDetailLoading = (state: ThemesDetailState) => state.loading;
export const getThemesDetailLoaded = (state: ThemesDetailState) => state.loaded;
