<section class="container">
  <header class="mb-0 mb-md-4">
    <span class="type-body-md text-secondary fw-bold w-100">
      {{ block.subtitle || ('content-block-activiteiten-subtitel' | fromDictionary) }}
    </span>
    <h1 class="h2 text-primary">
      {{ block.title || ('content-block-activiteiten-titel' | fromDictionary) }}
    </h1>
  </header>

  <main *ngIf="events?.length">
    <!--<app-slider [cursorArrow]="false" [total]="events!.length" [config]="sliderConfig">
      <div class="col-12 col-xsm-6 col-sm-6 col-md-6 col-lg-4" *ngFor="let event of events">
        <app-event-card [inverted]="true" [event]="event"></app-event-card>
      </div>
    </app-slider>-->

    <app-slider-v2>
      <swiper-slide class="swiper-slide" *ngFor="let event of events">
        <app-event-card class="swiper-slide__card" [event]="event"></app-event-card>
      </swiper-slide>
    </app-slider-v2>
  </main>
</section>
