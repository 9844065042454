import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { ContentBlock } from '../../models';
import { Initiative } from 'src/app/initiatives/models';
import { Story } from 'src/app/stories/models';
import { BaseContent } from 'src/app/content/models';
import { Event, EventData } from 'src/app/events/models';
import { Theme } from 'src/app/themes/models';

@Component({
  selector: 'app-mosaic-block',
  templateUrl: './mosaic-block.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MosaicBlockComponent implements OnInit {
  @Input() block!: ContentBlock;
  @Input() theme?: Theme;

  blocks: { block?: BaseContent; type: string }[] = [];
  maxBlockCount: number = 5;

  get urls() {
    let queryParams = {};
    if (this.theme !== undefined) {
      queryParams = { thema: this.theme.id };
    }
    return [
      {
        url: ['/verhalen'],
        queryParams,
        label: 'verhalen',
      },
      {
        url: ['/initiatieven'],
        queryParams,
        label: 'initiatieven',
      },
      {
        url: ['/activiteiten'],
        queryParams,
        label: 'activiteiten',
      },
    ];
  }

  ngOnInit(): void {
    //this.setRandomData();
    this.blocks =
      (this.block['content'] || [])
        ?.map(a => ({
          block: a,
          type: this.setType(a.url),
        }))
        .slice(0, this.maxBlockCount) || [];

    //this.blocks.splice(1, 0, { type: 'spacer' });
  }

  setType(url: string | string[]) {
    if (url.includes('initiatieven')) {
      return 'initiatief';
    }
    if (url.includes('verhalen')) {
      return 'verhaal';
    }
    if (url.includes('activiteiten')) {
      return 'evenement';
    }
    return '';
  }

  //setRandomData() {
  //  const types = [{ type: 'event' }, { type: 'story' }, { type: 'initiative' }];
  //  const newData = Array.from({ length: 6 }, (_, i) => types[Math.floor(Math.random() * types.length)]);

  //  this.randomData = newData;

  //  // Add spacer to shift layout
  //  this.randomData.splice(1, 0, { type: 'spacer' });
  //}

  asInitiative(item: BaseContent): Initiative {
    return item as Initiative;
  }
  asStory(item: BaseContent): Story {
    return item as Story;
  }
  asEvent(item: BaseContent): Event {
    return item as Event;
  }
}
